<template lang="pug">
  .VerificationDataDate
    p.text-center.text-primary.font-semibold.mt-8.mb-2
      | Validación de datos de cita
    article
      p.text-sm.text-info_color.mx-auto.max-w-xs.text-center
        | Verifica la información antes de programar tu cita
    card-date(
      :date="drugstoreState"
      :acordionNo="true"
      :acordionNoShow="true"
    ).max-w-sm.mx-auto.list-none
      div
        .flex.items-center
          .inline-block.mr-3
            div.mx-auto.Arrow.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none.opacity-50
              span(class="material-icons-outlined").text-primary
                | event_available
          article.m-0.w-full.flex.items-center
            p.mx-1: small.text-xs.text-primary: strong
              | {{ drugstoreState.label }}
            p.ml-2: small {{ drugstoreState.hora }}
          button.text-good_color(@click="() => changeTrip('Drugstore')")
            small.text-lg.material-icons-outlined edit
        hr.opacity-20.mt-2.mb-1
        .flex.items-center
          .inline-block.mr-3
            div.mx-auto.Arrow.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none.opacity-50
              span(class="material-icons-outlined").text-primary
                img(src="@/assets/images/pill_icon.svg")
          article.m-0.w-full
            p.mx-1: small.text-xs.text-info_color
              | Aqui podemos entregar las ordenes
              .flex.mt-3.dkmaxwidth(v-if="drugstoreState.numbers && !drugstoreState.reason")
                p: small.text-xs.mx-1.text-secondary(v-for="(numbe, i) in drugstoreState.numbers" :key="i") 
                  label(v-if="numbe != 9999999999999") {{numbe}}
                  label(v-else) No se especifico una formula
              .flex.mt-3.dkmaxwidth(v-if="drugstoreState.reason && drugstoreState.reason.formulas")
                p: small.text-xs.mx-1.text-secondary(v-for="(numb, i) in drugstoreState.reason.formulas.split(';')" :key="i") 
                  label(v-if="numb != 9999999999999") {{numb}}
                  label(v-else) No se especifico una formula
    div(v-show="progress")
      progress-bar( size="medium" :val="valueProgress" style="width: 100%; height: 30px;" :text="`Enviando archivo ... ${valueProgress}%`" :text-fg-color="'white'")

    footer.Footer.fixed.p-5.pt-2.max-w-lg.w-full
      button-component(
        :text="loading ? 'Enviando...' : 'Confirmar cita'"
        :disable="loading"
        themeSelected="secondary"
        :onClick="handleContinue")
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import loadingMessage from "../utils/loadingMessage";
import ProgressBar from 'vue-simple-progress'
var moment = require("moment");

var AWS = require("aws-sdk");
// var albumBucketName = 'cafam-dev-appointments';
var bucketRegion = "us-east-1";
var IdentityPoolId = "us-east-1:26afdf2d-930c-41e5-81a6-7a703020ee16";
// let uploadedfile = [];

export default {
  name: "VerificationDataDate",
  components: {
    CardDate: () => import("../components/CardDate.vue"),
    ButtonComponent: () => import("../components/general/Button.vue"),
    ProgressBar,
  },
  data() {
    return {
      loading: false,
      progress: false,
      valueProgress: 0,
      countFiles: 0,
    };
  },
  computed: {
    ...mapState({
      drugstoreState: (state) => state.drugstore.drugstoreState,
      env: (state) => state.environment.environment.env,
      userData: (state) => state.root.userData,
      user: (state) => state.user.userData,
      responseAppointment: (state) =>  state.appointments.responseAppointment,
      formDocuments: (state) => state.formDocuments.formDocumentsState,
      closeModal: (state) => state.root.closeModal,
      schedule: (state) => state.appointments.schedule,
      appointments: (state) => state.appointments.appointments,
      eps: (state) => state.eps.epsState,
    }),
  },
  methods: {
    ...mapActions("root", [
      "changeTrip",
      "handleShowModal",
    ]),
    ...mapMutations("root", [
      "setState"
    ]),
    ...mapActions("formDocuments", [
      "reset"
    ]),
    ...mapActions(["createAppointment", "updateAppointment", "fetchAppointments", "postUsers", "setProcessing"]),
    async handleContinue() {

      try {

        // if code exist is for update
        if(this.drugstoreState.code){

          this.handleShowModal(loadingMessage({
            title: "Actualizando tu cita...",
            state: "loading",
          }));

          let data = {
            branch: this.drugstoreState.branch,
            status: 'CONFIRMED',
            schedule: this.drugstoreState.schedule,
            code: this.drugstoreState.code,
            startAt: this.drugstoreState.startDate,
          };
          
          this.loading = true;
          await this.updateAppointment({
              url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
              headers: {
                'token_appointments': this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY,
              },
              queryData: '',
              data: data
          });
          this.loading = false;
          if(this.responseAppointment.status == 'CONFIRMED'){

            // Send data action
            this.setProcessing({
              document: this.userData.documentNumber,
              timestamp_click: moment().toDate().getTime(),
              appointment_code: this.drugstoreState.code,
              action: 'Modificación'
            });

            // LS.setItem("user_info", users);
            this.postUsers({
              id: this.userData.id,
              name: this.userData.name,
              document: this.userData.documentNumber,
              documentType: this.userData.dcType,
              email: this.userData.email,
              phone: this.userData.phone,
              address: this.userData.address
            });

            // reset store
            this.reset();

            // this.handleCloseModalButton();
            this.handleShowModal(loadingMessage({
              title: "Cita actualizada con éxito",
              state: "success",
            }));

            let queryData = '?customer.dni='+ this.userData.documentNumber + "&status=CONFIRMED";
            await this.fetchAppointments({
              url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
              headers: {
                'token_appointments': this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY,
              },
              queryData: queryData
            });

            if(this.appointments.length > 0){
              this.changeTrip('Home_dates');
            } else this.changeTrip("ID");

          }else{
            throw new Error(this.error)
          }

        }else{

          console.log(this.drugstoreState.zonedStartDate)

          let data = {
            branch: this.drugstoreState.branch_id,
            schedule: this.schedule.id,
            startAt: this.drugstoreState.zonedStartDate.slice(0, -4)+'500', //remove : character
            user: {
              "firstName": this.userData.name, 
              "lastName": "",
              "dni": this.userData.documentNumber,
              "cuit": 1,
              "email": this.userData.email || this.user.email,
              "phone": this.userData.phone || this.user.phone
            },
            reason: JSON.stringify({
              formulas: this.drugstoreState.numbers.join(';'),
              point: this.drugstoreState.cost_center,
              eps: this.eps.code
            })
          };
          console.log(data)
          this.loading = true;
          this.handleShowModal(loadingMessage({
            title: "Agendando cita...",
            state: "loading",
          }));
          
          await this.createAppointment({
            url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
            headers: {
              "token_appointments": this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY,
            },
            queryData: "",
            data: data
          });
          // this.loading = false;
          // Error appointment
          if(!this.responseAppointment.status || this.responseAppointment.status != 'CONFIRMED'){
            console.error("[error: Falla en el servicio]:");
            console.log(this.responseAppointment)
            this.handleShowModal(loadingMessage({
              title: "Lo sentimos ocurrió un error al agendar la cita: " + this.responseAppointment.message,
              state: "danger",
            }));
            this.btn_text = 'Aceptar';
            this.loading = false;
            return;
          }

          // upload to s3
          if(this.formDocuments.filter(f=>f.files && f.files.length>0).length > 0) {
            console.log('SUBIR AL S3')
            this.handleShowModal(loadingMessage({
              title: "Subiendo imagenes...",
              state: "loading",
              showProgress: true,
            }));
            
            let reason_formulas = JSON.parse(data.reason)
            reason_formulas = reason_formulas.formulas.split(';')

            // await to s3
            /* eslint-disable */
            await new Promise(async resolve => {
              this.sendFiles(reason_formulas, resolve);
            });
            /* eslint-enable */
          }

          this.loading = false;

          // Confirmed appointment
          this.changeTrip("DateAgended");
          this.closeModal();

        }

      } catch (error) {
        console.error(error);
        this.handleShowModal(loadingMessage({
          title: "Error al subir tus imágenes o al agendar tu cita",
          state: "danger",
        }));
      }
    },
    /* eslint-disable */
    async sendFiles(reason_formulas, resolve){
      
      if(this.countFiles>=reason_formulas.length){
        resolve()
      }else{
        let element = reason_formulas[this.countFiles]
        this.formDocuments.map(async f=>{
          if(f.numero == element){
            let key = `${element}/${this.responseAppointment.code || 'appointment_code'}/`;
            for (let i = 0; i < f.files.length; i++) {
              const file = f.files[i];
              key += `_${file.id}`;
              await this.uploadtoS3(key, file.objectUrl, file.type)
            }
            this.countFiles++
            this.sendFiles(reason_formulas, resolve);
          }
        })
      }
      
    },
    /* eslint-enable */
    async uploadtoS3(key, file, type){
      console.log('Init upload s3')

      this.progress = true;
      this.setState({
        key: "valueProgress",
        value: 0,
      });
      this.valueProgress = 0;
      return new Promise(resolve=> {

        type = type.split('/')
        type = type.length > 1 ? type[1] : type[0];

        let destiny = key + '.' + type;
        var opts = {queueSize: 1, partSize: 1024 * 1024 * 5};
        var params = {Key: destiny, ContentType: type, Body: file};

        let s3 = new AWS.S3({
          params: {
            Bucket: this.env.VUE_APP_BUCKET,
            Key: destiny,
            Body: file
          }
        });
        var upload = s3.upload(params,opts)
        .on('httpUploadProgress', (evt) => {
          // console.log("Uploaded :: " + parseInt((evt.loaded * 100) / evt.total)+'%');
          this.setState({
            key: "valueProgress",
            value: parseInt((evt.loaded * 100) / evt.total),
          });
          this.valueProgress = parseInt((evt.loaded * 100) / evt.total);
        })
        .promise();

        /* eslint-disable */
        upload.then(async (data, err) => {
          
          if(!err){
            console.log("************* Successfully uploaded file. *****************");
            console.log(data)
            this.progress = false;

            resolve();

          } else {
            console.log("************* ERROR! *****************");
            console.log(err)
            this.handleShowModal(loadingMessage({
              title: "Lo sentimos ocurrió un error al agendar la cita: " + err,
              state: "danger",
            }));
            this.btn_text = 'Aceptar';
            this.loading = false;
            return;
          }

        });
      } )
    }
  },

  mounted(){
    setTimeout(() => {
      AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: IdentityPoolId
        })
      });
    }, 1000);
  }
}
</script>

<style scoped>
.dkmaxwidth {
  max-width: 80%;
  overflow-x: auto;
}
</style>